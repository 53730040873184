module.exports = [{
      plugin: require('../plugins/segment/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"zhZ3WS45uCRFfoZxlUyJn1jfeNCkvBu4","trackPage":false,"ccpaLibURL":"https://pr150--aaa-ncnu--mwg-digital-master.calstate.aaabeta.com/privacy-rights/v1/lib","ccpaApiURL":"https://pr150--aaa-ncnu--mwg-digital-master.calstate.aaabeta.com/endpoints/privacy-rights/v1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"disableBgImage":true,"maxWidth":2880}},{"resolve":"gatsby-remark-copy-linked-files","options":{}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
