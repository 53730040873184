export const ALARM_LOGIN_URL = process.env.GATSBY_ALARM_LOGIN_URL;
export const API_BASE_URL = process.env.GATSBY_API_BASE_URL;
export const API_CONTACTUS_URL = process.env.GATSBY_API_CONTACTUS_URL;
export const API_ZIP_CHECK_PATH = process.env.GATSBY_API_ZIP_CHECK_PATH;
export const API_IP_CHECK_PATH = process.env.GATSBY_API_IP_CHECK_PATH;
export const API_CUSTOMER_CHECK_PATH =
  process.env.GATSBY_API_CUSTOMER_CHECK_PATH;
export const API_FORM_HANDLER_GCF_URL =
  process.env.GATSBY_API_FORM_HANDLER_GCF_URL;
export const API_VALIDATE_PROMO_CODE_PATH =
  process.env.GATSBY_API_VALIDATE_PROMO_CODE_PATH;
export const COOKIES_DOMAIN = process.env.GATSBY_COOKIES_DOMAIN;
export const COOKIES_ZIP_KEY = process.env.GATSBY_COOKIES_ZIP_KEY;
export const PRIVACY_RIGHTS_PATH = process.env.GATSBY_PRIVACY_RIGHTS_PATH;
export const PRIVACY_RIGHTS_URL = process.env.GATSBY_PRIVACY_RIGHTS_URL;

export const SEGMENT_WRITE_KEY = process.env.GATSBY_SEGMENT_WRITE_KEY;
export const SEGMENT_TRACK_PAGE =
  process.env.GATSBY_SEGMENT_TRACK_PAGE === 'true';

export const INTERNAL_LINKS_REGXS = [
  /^#/,
  /^mailto:/,
  /^tel:/,
  /^https:\/\/mysg.smarthome.calstate.aaa.com/,
  /^https:\/\/safesecurity.com/,
  /^https:\/\/get.smarthome.calstate.aaa.com/,
];

export const CLUB_ARIZONA = 2;

export const SH_COOKIE_ACCOUNT = 'sh_account';
export const CCPA_LIB_URL = process.env.GATSBY_CCPA_LIB_URL;
export const CCPA_API_URL = process.env.GATSBY_CCPA_API_URL;

export const COOKIES_PRIVACY = 'sh_privacy';

export const VIEWPORT_DEFAULT =
  'width=device-width, initial-scale=1.0, maximum-scale=6.0';
