import Cookies from 'js-cookie';
import {
  CCPA_API_URL,
  COOKIES_DOMAIN,
  SEGMENT_TRACK_PAGE,
  SEGMENT_WRITE_KEY,
  SH_COOKIE_ACCOUNT,
} from './Constants';

export const checkAccount = (
  writeKey = SEGMENT_WRITE_KEY,
  trackPage = SEGMENT_TRACK_PAGE,
  ccpaApiURL = CCPA_API_URL
) => {
  let email = Cookies.get(SH_COOKIE_ACCOUNT);
  if (email === undefined) {
    window.DNS_OPTOUT.connectSegmentIO(writeKey, trackPage);
  } else {
    fetch(`${ccpaApiURL}?email=${email}`, { method: 'GET' })
      .then(resp => {
        resp.json().then(res => {
          if (res.opted_out) {
            window.DNS_OPTOUT.disableSegmentIOTracking();
          } else {
            window.DNS_OPTOUT.enableSegmentIOTracking();
          }
          window.DNS_OPTOUT.connectSegmentIO(writeKey, trackPage);
        });
      })
      .catch(() => {
        console.error('Unable to get opt out status');
      });
  }
};

export const updateAccount = email => {
  if (window && window.DNS_OPTOUT) {
    let account = Cookies.get(SH_COOKIE_ACCOUNT);
    if (account != email) {
      Cookies.set(SH_COOKIE_ACCOUNT, email, { domain: COOKIES_DOMAIN });
      if (window.analytics) {
        delete window.analytics;
      }
      window.DNS_OPTOUT.initializeSegmentIO();
      checkAccount();
    }
  }
};
